/*-------- 3. Header style ---------*/

.header-top-ptb-1 {
    padding: 7px 0;
}

.header-info-right {
    display: flex;
    justify-content: flex-end;
}
.header-info {
    > ul {
        display: flex;
        > li {
            margin-right: 30px;
            position: relative;
            display: flex;
            align-items: center;
            &:before {
                content: '';
                position: absolute;
                right: -14px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 20px;
                background: #dedfe2;
            }
            &:last-child {
                margin-right: 0;
                &:before {
                    display: none;
                }
            }
            a {
                color: #444;
                font-weight: 500;
                i {
                    font-size: 14px;
                    margin-right: 5px;
                }
                &.language-dropdown-active {
                    i {
                        font-size: 8px;
                        margin-left: 5px;
                    }
                }
            }
            > ul {
                &.language-dropdown {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 2;
                    min-width: 200px;
                    background: #fff;
                    border-radius: 5px;
                    transform: translateY(20px);
                    box-shadow: 0 -3px 23px rgba(0,0,0,.06);
                    visibility: hidden;
                    opacity: 0;
                    padding: 20px 0;
                    transition: all .25s cubic-bezier(.645,.045,.355,1);
                }
                li {
                    display: block;
                    a {
                        display: block;
                        color: #696969;
                        padding: 11px 20px 12px;
                        &:hover {
                            color: $theme-color-blue;
                            background-color: rgba(78,151,253,.1);
                        }
                    }
                }
            }
            &:hover {
                > a {
                    color: #333;
                }
                > ul {
                    &.language-dropdown {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}

.covid-update {
    p {
        a {
            background-color: #3751ee;
            font-weight: 500;
            color: $white;
            display: inline-block; 
            margin: 0 10px 0 0;
            border-radius: 5px;
            padding: 1px 12px;
            &:hover {
                background-color: $theme-color-red;
            }
        }
        color: #444;
        
    }
    &.covid-update-mobile {
        padding: 0px 0 23px;
        p {
            color: #fff;
        }
    }
}

.header-wrap {
    display: flex;
    align-items: center;
    &.header-space-between {
        justify-content: space-between;
    }
}

.logo {
    a {
        display: block;
    }
    &.logo-width-1 {
        a {
            img {
                width: 166px;
                @media #{$xs-layout} {
                    width: 120px;
                } 
            }
        }
    }
    @media #{$lg-layout} {
        &.logo-hm3 {
            a {
                img {
                    width: 120px;
                }
            }
        }
    } 
}

.main-menu {
    &.main-menu-grow {
        flex-grow: 1;
    }
    &.main-menu-mrg-1 {
        margin: 0 0 0 27px;
        @media #{$lg-layout} {
            margin: 0 0 0 15px;
        } 
    }
    > nav {
        > ul {
            > li {
                display: inline-block;
                position: relative;
                &.position-static {
                    position: static;
                }
                > a {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 500;
                    color: $gray;
                    i {
                        font-size: 8px;
                        position: relative;
                        top: -3px;
                        margin-left: 4px;
                    }
                    &.active {
                        color: $theme-color-blue;
                    }
                }
                ul {
                    &.sub-menu {
                        position: absolute;
                        left: 0;
                        top: 100%;
                        box-shadow: 0 -3px 23px rgba(0,0,0,.06);
                        background-color: $white;
                        width: 275px;
                        padding: 33px 0 35px;
                        transition: all .25s ease 0s;
                        opacity: 0;
                        visibility: hidden;
                        margin-top: 20px;
                        border-radius: 5px;
                        z-index: 999;
                        li { 
                            line-height: 1;
                            display: block;
                            margin-bottom: 21px;
                            position: relative;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            ul {
                                &.level-menu {
                                    position: absolute;
                                    left: 100%;
                                    top: -110px;
                                    box-shadow: 0 -3px 23px rgba(0,0,0,.06);
                                    background-color: $white;
                                    width: 240px;
                                    padding: 33px 0 35px;
                                    transition: all .25s ease 0s;
                                    opacity: 0;
                                    visibility: hidden;
                                    border-radius: 5px;
                                    margin-top: 20px;
                                    &.level-menu-modify {
                                        top: -25px;
                                        @media #{$lg-layout} {
                                            width: 165px;
                                        }
                                    }
                                }
                            }
                            a {
                                font-size: 16px;
                                color: #777; 
                                display: block;
                                padding: 0 30px;
                                i {
                                    font-size: 9px;
                                    float: right;
                                    position: relative;
                                    top: 4px;
                                }
                            }
                            &:hover {
                                > a {
                                    color: $black;
                                }
                                ul {
                                    &.level-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        margin-top: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
                ul {
                    &.mega-menu {
                        position: absolute;
                        left: 0;
                        top: 100%;
                        box-shadow: 0 -3px 23px rgba(0,0,0,.06);
                        background-color: $white;
                        width: 100%;
                        padding: 35px 25px 35px 35px;
                        transition: all .25s ease 0s;
                        opacity: 0;
                        visibility: hidden;
                        margin-top: 20px;
                        border-radius: 5px;
                        z-index: 999;
                        li {
                            line-height: 1;
                            display: block;
                            position: relative;
                            float: left;
                            padding-right: 10px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.sub-mega-menu-width-22 {
                                width: 22%;
                            }
                            &.sub-mega-menu-width-34 {
                                width: 34%;
                            }
                            a {
                                &.menu-title {
                                    font-size: 16px;
                                    font-weight: 500;
                                    display: block;
                                }
                            }
                            ul {
                                margin-top: 28px;
                                li {
                                    line-height: 1;
                                    display: block;
                                    margin-bottom: 15px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    a {
                                        font-size: 16px;
                                        color: #696969;
                                        display: block;
                                        line-height: 1.4;
                                        &:hover {
                                            color: $black;
                                        }
                                    }
                                }
                            }
                            .menu-banner-wrap {
                                overflow: hidden;
                                position: relative;
                                a {
                                    display: block;
                                    img {
                                        width: 100%;
                                        transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
                                    }
                                }
                                .menu-banner-content {
                                    position: absolute;
                                    top: 32px;
                                    left: 30px;
                                    @media #{$lg-layout} {
                                        left: 20px;
                                        top: 22px;
                                    }
                                    h4 {
                                        font-size: 15px;
                                        text-transform: uppercase;
                                        font-weight: 500;
                                        margin: 0;
                                    }
                                    h3 {
                                        font-size: 24px;
                                        font-weight: 700;
                                        line-height: 1.5;
                                        margin: 5px 0 11px;
                                        @media #{$lg-layout} {
                                            font-size: 20px;
                                            line-height: 1.3;
                                            margin: 5px 0 7px;
                                        }
                                    }
                                    .menu-banner-price {
                                        margin: 0 0 28px;
                                        @media #{$lg-layout} {
                                            margin: 0 0 10px;
                                        }
                                        span {
                                            &.new-price {
                                                font-size: 24px;
                                                font-weight: 700;
                                                color: $theme-color-red;
                                            }
                                            &.old-price {
                                                font-size: 16px;
                                                font-weight: 400;
                                                color: #696969;
                                                text-decoration: line-through;
                                                margin-left: 5px;
                                            }
                                        }
                                    }
                                    .menu-banner-btn {
                                        a {
                                            display: inline-block;
                                            font-size: 14px;
                                            font-weight: 700;
                                            color: $white;
                                            border-radius: 26px;
                                            padding: 12px 22px 15px;
                                            background-color: $theme-color-blue;
                                            @media #{$lg-layout} {
                                                padding: 10px 16px 13px;
                                            }
                                        }
                                    }
                                }
                                .menu-banner-discount {
                                    width: 80px;
                                    height: 80px;
                                    line-height: 80px;
                                    border-radius: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: rgb(255, 213, 90);
                                    position: absolute;
                                    top: 13%;
                                    right: 7%;
                                    @media #{$lg-layout} {
                                        width: 70px;
                                        height: 70px;
                                        line-height: 70px;
                                    }
                                    h3 {
                                        color: $black;
                                        margin: 0;
                                        text-align: center;
                                        font-size: 16px;
                                        font-weight: 600;
                                        line-height: 1;
                                        span {
                                            display: block;
                                            line-height: 1;
                                            font-weight: 700;
                                            font-size: 20px;
                                            margin: 0 0 3px;
                                        }
                                    }
                                }
                                &:hover {
                                    a {
                                        img {
                                            transform: scale3d(1.1, 1.1, 1.1);
                                        }
                                    }
                                    .menu-banner-btn {
                                        a {
                                            background-color: $theme-color-red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover {
                    > a {
                        color: $theme-color-blue;
                    }
                    ul {
                        &.sub-menu {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 0;
                        }
                        &.mega-menu {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    } 
    &.hover-boder {
        > nav {
            > ul {
                > li {
                    > a {
                        position: relative;
                        &::after {
                            content: '';
                            position: absolute;
                            left: auto;
                            right: 0;
                            bottom: 34px;
                            height: 1px;
                            width: 0;
                            transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
                            background: $theme-color-blue;
                        }
                    }
                    &:hover {
                        > a {
                            &::after {
                                width: 100%;
                                left: 0;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
        &.hover-boder-white {
            > nav {
                > ul {
                    > li {
                        > a {
                            &::after {
                                bottom: 18px;
                                background: $white;
                            }
                        }
                    }
                }
            }
        }
        &.hover-boder-modify {
            > nav {
                > ul {
                    > li {
                        > a {
                            &::after {
                                bottom: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.main-menu-light-white {
        > nav {
            > ul {
                > li {
                    > a {
                        color: rgba(255,255,255,1);
                    }
                }
            }
        }
    }
    &.main-menu-padding-1 {
        > nav {
            > ul {
                > li {
                    padding: 0 17px;
                    @media #{$xl-layout} {
                        padding: 0 14px;
                    }
                    @media #{$lg-layout} {
                        padding: 0 8px;
                    }
                }
            }
        }
        @media #{$lg-layout} {
            &.hm3-menu-padding {
                > nav {
                    > ul {
                        > li {
                            padding: 0 6px;
                        }
                    }
                }
            }
        }
    }
    &.main-menu-lh-1 {
        > nav {
            > ul {
                > li {
                    line-height: 92px;
                }
            }
        }
    }
    &.main-menu-lh-2 {
        > nav {
            > ul {
                > li {
                    line-height: 60px;
                }
            }
        }
    }
    &.main-menu-lh-3 {
        > nav {
            > ul {
                > li {
                    line-height: 80px;
                }
            }
        }
    }
}

.header-action-right {
	display: flex;
	justify-content: flex-end;
    flex-shrink: 0;
    align-items: center;
}

.search-style-1 {
    margin-right: 28px;
    @media #{$lg-layout} {
        margin-right: 15px;
    }
    form {
        width: 370px;
        position: relative;
        @media #{$xx-layout} {
            width: 280px;
        } 
        @media #{$xl-layout} {
            width: 220px;
        }
        @media #{$lg-layout} {
            width: 170px;
        } 
        input {
            font-size: 15px;
            height: 48px;
            color: #444;
            border-radius: 26px;
            padding: 3px 50px 3px 20px;
            border: 1px solid #f5f5f5;
            background-color: #f5f5f5;
            transition: all .25s cubic-bezier(.645,.045,.355,1);
            &::-moz-input-placeholder {
                color: #444;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: #444;
                opacity: 1;
            }
            &:focus {
                border: 1px solid $theme-color-blue;
                background-color: $white;
            }
        }
        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            border: none;
            font-size: 16px;
            height: 100%;
            padding: 0 15px;
            background-color: transparent;
            color: $theme-color-blue;
            &:hover {
                color: $theme-color-red;
            }
        }
    }
}

.header-action {
    display: flex;
    align-items: center;
    .header-action-icon {
        margin-right: 28px;
        position: relative;
        display: flex;
        &.header-action-mrg-none {
            margin-right: 13px;
            @media #{$md-layout} {
                margin-right: 33px;
            } 
            @media #{$xs-layout} {
                margin-right: 15px;
            } 
            @media #{$sm-layout} {
                margin-right: 20px;
            }
        }
        &.header-action-mrg-none2 {
            margin-right: 0px;
            @media #{$md-layout} {
                margin-right: 33px;
            } 
            @media #{$xs-layout} {
                margin-right: 15px;
            } 
            @media #{$sm-layout} {
                margin-right: 20px;
            }
        }
        @media #{$md-layout} {
            margin-right: 33px;
        } 
        @media #{$xs-layout} {
            margin-right: 15px;
        } 
        @media #{$sm-layout} {
            margin-right: 20px;
        } 
        &:last-child {
            margin-right: 0;
        }
        > a {
            font-size: 24px;
            color: $black;
            line-height: 1;
            display: inline-block;
            position: relative;
            @media #{$xs-layout} {
                font-size: 20px;
            } 
            @media #{$sm-layout} {
                font-size: 22px;
            } 
            span {
                &.pro-count {
                    position: absolute;
                    right: -11px;
                    bottom: -8px;
                    color: $white;
                    height: 20px;
                    width: 20px;
                    border-radius: 100%;
                    font-weight: 700;
                    font-size: 12px;
                    text-align: center;
                    line-height: 20px;
                    &.blue {
                        background-color: $theme-color-blue;
                    }
                }
            }
        }
        &:hover {
            > a {
                color: $theme-color-blue;
            }
            .cart-dropdown-wrap {
                opacity: 1;
                visibility: visible;
                top: 100%;
            }
        }
    }
    @media #{$lg-layout} {
        &.header-action-hm3 {
            .header-action-icon {
                margin-right: 15px;
                &.header-action-mrg-none2 {
                    margin-right: 0px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    } 
}

.header-action-2 {
    display: flex;
    align-items: center;
    .header-action-icon-2 {
        position: relative;
        display: flex;
        padding: 0 14px;
        @media #{$xs-layout} {
            padding: 0 6px;
        } 
        @media #{$sm-layout} {
            padding: 0 10px;
        } 
        &:last-child {
            padding: 0 0px 0 14px;
            @media #{$xs-layout} {
                padding: 0 0px 0 6px;
            }
            @media #{$sm-layout} {
                padding: 0 0px 0 10px;
            }
        }
        > a {
            font-size: 30px;
            color: $black;
            line-height: 1;
            display: inline-block;
            position: relative;
            @media #{$md-layout} {
                color: $white;
                fill: $white;
            } 
            @media #{$xs-layout} {
                color: $white;
                fill: $white;
            } 
            svg {
                width: 1em;
                height: 1em;
            }
            @media #{$xs-layout} {
                font-size: 20px;
            } 
            @media #{$sm-layout} {
                font-size: 22px;
            } 
            span {
                &.pro-count {
                    position: absolute;
                    right: -11px;
                    bottom: -8px;
                    color: $white;
                    height: 20px;
                    width: 20px;
                    border-radius: 100%;
                    font-weight: 700;
                    font-size: 12px;
                    text-align: center;
                    line-height: 20px;
                    &.blue {
                        background-color: $theme-color-blue;
                    }
                }
            }
        }
        &:hover {
            > a {
                color: $theme-color-blue;
                fill: $theme-color-blue;
            }
            .cart-dropdown-wrap {
                opacity: 1;
                visibility: visible;
                top: 100%;
            }
        }
    }
}
.header-middle-ptb-1 {
	padding: 21px 0 27px;
}

.cart-dropdown-wrap {
	position: absolute;
	right: 0;
	top: calc(100% + 20px);
	z-index: 99;
    width: 360px;
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0,0,0,.12);
    padding: 30px 20px 27px;
    opacity: 0;
    visibility: hidden;
    transition: all .25s cubic-bezier(.645,.045,.355,1);
    @media #{$xs-layout} {
        width: 290px;
        right: -39px;
        &.cart-dropdown-hm2 {
            right: -63px;
        }
    } 
    @media #{$sm-layout} {
        width: 310px;
    } 
    ul {
        li {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 20px;
            &:last-child {
                margin: 0 0 0px;
            }
            .shopping-cart-img {
                flex: 0 0 80px;
                margin-right: 20px;
                @media #{$xs-layout} {
                    flex: 0 0 60px;
                    margin-right: 10px;
                } 
                a {
                    display: block;
                    img {
                        max-width: 100%;
                    }
                }
            }
            .shopping-cart-title {
                margin: 6px 0 0;
                h4 {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 1;
                    margin: 0 0 9px;
                    a {
                        color: #696969;
                        &:hover {
                            color: $theme-color-blue;
                        }
                    }
                }
                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1;
                    margin: 0 0 0px;
                    color: $theme-color-blue;
                    span {
                        color: #696969;
                        font-weight: 400;
                        font-size: 15px;
                    }
                }
            }
            .shopping-cart-delete {
                display: flex;
                flex-grow: 100;
                justify-content: flex-end;
                margin: 6px 0 0;
                a {
                    font-size: 18px;
                    color: #696969;
                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }
    .shopping-cart-footer {
        .shopping-cart-total {
            border-top: 2px solid #f3f3f3;
            margin: 27px 0 20px;
            padding: 17px 0 0;
            h4 {
                color: #9b9b9b;
                font-weight: 700;
                font-size: 15px;
                margin: 0;
                span {
                    font-size: 18px;
                    float: right;
                    color: $theme-color-blue;
                }
            }
        }
        .shopping-cart-button {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
            a {
                display: inline-block;
                font-size: 15px;
                color: $white;
                font-weight: bold;
                border-radius: 50px;
                line-height: 1;
                padding: 16px 42px 17px;
                background-color: $theme-color-blue;
                @media #{$xs-layout} {
                    padding: 12px 22px 13px;
                } 
                &:hover {
                    background-color: $theme-color-red;
                }
            }
        }
    }
}

.sticky-bar {
    &.stick {
        animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        box-shadow: 0 8px 20px 0 rgba(0,0,0,.1);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        left: 0; 
        transition: all .3s ease 0s;
        &.sticky-white-bg {
            background-color: #fff;
        }
        &.sticky-blue-bg {
            background-color: #415da1;
        }
    }
}

.header-height-1 {
    min-height: 133px;
    @media #{$lg-layout} {
        min-height: 157px;
    } 
    @media #{$md-layout} {
        min-height: 83px;
    } 
    @media #{$xs-layout} {
        min-height: 65px;
    } 
}
.header-height-2 {
    min-height: 200px;
    @media #{$lg-layout} {
        min-height: 224px;
    } 
    @media #{$md-layout} {
        min-height: 83px;
    } 
    @media #{$xs-layout} {
        min-height: 65px;
    } 
}

.header-height-3 {
    min-height: 132px;
    @media #{$lg-layout} {
        min-height: 133px;
    } 
    @media #{$md-layout} {
        min-height: 83px;
    } 
    @media #{$xs-layout} {
        min-height: 65px;
    } 
}

.header-height-4 {
    min-height: 120px;
    @media #{$lg-layout} {
        min-height: 144px;
    } 
    @media #{$md-layout} {
        min-height: 83px;
    } 
    @media #{$xs-layout} {
        min-height: 65px;
    } 
}

.search-style-2 {
    form {
        display: flex;
        position: relative;
        select {
            color: #444;
            font-size: 15px;
            min-height: 52px;
            border: 2px solid #eee;
            border-right: none;
            width: 170px;
            padding: 3px 35px 3px 20px;
            border-radius: 26px 0 0 26px;
            background: transparent url("../../assets/images/icon-img/select.png") no-repeat center right 20px;
            background-color: #f8f8f8;
            transition: all .3s ease 0s;
            -moz-appearance: none;
            -webkit-appearance: none;
            &:focus {
                color: $black;
            }
        }
        input {
            color: #444;
            font-size: 15px;
            min-height: 52px;
            border: 2px solid #eee;
            width: 540px;
            padding: 3px 62px 3px 20px;
            border-radius: 0px 26px 26px 0px;
            background-color: #fff;
            @media #{$lg-layout} {
                width: 340px;
            } 
            &::-webkit-input-placeholder {
                color: #444;
                opacity: 1;
            }
            &::-moz-input-placeholder {
                color: #444;
                opacity: 1;
            }
            &:focus {
                border: 2px solid $theme-color-blue;
            }
        }
        button {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            padding: 0;
            border: none;
            font-size: 20px;
            color: $theme-color-blue;
            height: 100%;
            padding: 5px 17px;
        }
    }
}
.header-bottom-bg-color {
    background-color: #415da1;
}
.main-categori-wrap {
    position: relative;
    > a {
        display: flex;
        align-items: center;
        span {
            margin-right: 10px;
        }
        font-size: 16px;
        color: $white;
        font-weight: 700;
        @media #{$xs-layout} {
            font-weight: 500;
        }
        > i {
            font-size: 14px;
            margin-left: 15px;
            font-weight: 300;
            &.up {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                opacity: 0;
                visibility: hidden;
            }
        }
        &.open {
            > i {
                opacity: 0;
                visibility: hidden;
                &.up {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.hotline {
    p {
        color: $white;
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center;
        @media #{$lg-layout} {
            font-size: 15px;
        } 
        i {
            font-size: 24px;
            margin-right: 10px;
            color: $theme-color-blue;
        }
        span {
            font-size: 14px;
            margin-right: 10px;
            font-weight: 400;
        }
    }
}

.categori-dropdown-active-large {
    list-style-type: none;
	position: absolute;
	top: 177%;
	left: 0;
	z-index: 9;
	margin: 0;
	padding: 15px 0 23px;
	background: #fff;
	border: 1px solid #f5f5f5;
	border-radius: 5px;
	box-shadow: 0 -3px 23px rgba(0,0,0,.06);
	font-size: 15px;
	font-weight: 400;
	min-width: 270px;
    transition: all .25s cubic-bezier(.645,.045,.355,1);
    opacity: 0;
    visibility: hidden;
    &.open {
        opacity: 1;
        visibility: visible;
    }
}
.categori-dropdown-active-small {
	z-index: 9;
	margin: 0;
	padding: 15px 0 23px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 -3px 23px rgba(0,0,0,.06);
	font-size: 15px;
	font-weight: 400;
    display: none;
    width: 100%;
}

.categori-dropdown-wrap {
    ul {
        li {
            display: block;
            a {
                display: flex;
                align-items: center;
                padding: 5px 34px;
                line-height: 1.5;
                color: #444;
                font-size: 15px;
                @media #{$xs-layout} {
                    padding: 5px 12px;
                }
                i {
                    margin-right: 16px;
                    font-size: 20px;
                }
                &:hover {
                    color: $theme-color-blue;
                    background-color: rgba(78,151,253,.1);
                }
            }
        }
    }
}
.header-bottom-shadow {
    box-shadow: 0 1px 20px rgba(0,0,0,.05);
}
.search-style-3 {
    form {
        position: relative;
        input {
            font-size: 15px;
            height: 40px;
            color: #444;
            border-radius: 26px;
            padding: 3px 50px 3px 20px;
            border: 1px solid $white;
            background-color: $white;
            transition: all .25s cubic-bezier(.645,.045,.355,1);
            &::-moz-input-placeholder {
                color: #444;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: #444;
                opacity: 1;
            }
            &:focus {
                border: 1px solid $theme-color-blue;
            }
        }
        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            border: none;
            font-size: 14px;
            height: 100%;
            padding: 0 14px;
            background-color: transparent;
            color: $black;
            &:hover {
                color: $theme-color-blue;
            }
        }
    }
}
.search-popup-wrap {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	background-color: rgba(255, 255, 255, 1);
	transition: all .5s ease-in-out;
	z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    .search-popup-content {
        form {
            input { 
                width: 1170px;
                background-color: transparent;
                border: 0;
                border-bottom: 3px solid $theme-color-blue;
                font-size: 50px;
                padding: 21px 50px 16px 0px;
                color: #919191;
                font-weight: 300;
                max-width: 100%;
                height: auto;
                transform: translate(0,50px);
                transition-delay: 0s;
                opacity: 0;
                transition: all .5s ease-in-out,opacity .5s linear;
                &::-moz-input-placeholder {
                    color: #919191;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color: #919191;
                    opacity: 1;
                }
                @media #{$xl-layout} {
                    width: 1000px;
                }
                @media #{$lg-layout} {
                    width: 820px;
                }
                @media #{$md-layout} {
                    width: 650px;
                    font-size: 35px;
                }
                @media #{$xs-layout} {
                    width: 270px;
                    font-size: 25px;
                    padding: 21px 20px 12px 0px;
                }
                @media #{$sm-layout} {
                    width: 480px;
                    font-size: 25px;
                    padding: 21px 20px 12px 0px;
                }
            }
        }
    }
    &.search-visible {
        visibility: visible;
	    opacity: 1;
        transition-delay: 0s;
        .search-popup-content {
            form {
                input { 
                    transform: none;
                    opacity: 1;
                    transition-delay: .6s;
                }
            }
        }
    }
}

.close-style-wrap {
    position: absolute;
    right: 55px;
    top: 40px;
    &.close-style-position-inherit {
        position: inherit;
    }
    .close-style {
        position: relative;
        background-color: transparent;
        padding: 0;
        border: none;
        width: 40px;
        height: 40px;
        > i {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 29px;
            height: 2px;
            margin: 0 !important;
            transform-origin: center;
            display: block;
            overflow: hidden;
            transition: transform .6s cubic-bezier(.165,.84,.44,1),-webkit-transform .6s cubic-bezier(.165,.84,.44,1);
            &::before , &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: "";
                background-color: #111;
            }
            &.icon-top {
                transform: translate(-50%,-50%) rotate(45deg);
                &::before {
                    transition: transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s,-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s;
                    transform: scaleX(1) translateZ(0);
                    transform-origin: right;
                }
                &::after {
                    transition: transform .6s cubic-bezier(0.165,0.84,0.44,1),-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1);
                    transform: scaleX(0) translateZ(0);
                    transform-origin: left;
                }
            }
            &.icon-bottom {
                transform: translate(-50%,-50%) rotate(-45deg);
                &::before {
                    transition: transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s,-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s;
                    transform: scaleX(1) translateZ(0);
                    transform-origin: right;
                }
                &::after {
                    transition: transform .6s cubic-bezier(0.165,0.84,0.44,1),-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1);
                    transform: scaleX(0) translateZ(0);
                    transform-origin: left;
                }
            }
        }
        &:hover {
            i {
                &.icon-top {
                    &::before {
                        transition: transform .6s cubic-bezier(0.165,0.84,0.44,1),-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1);
                        transform: scaleX(0) translateZ(0);
                    }
                    &::after {
                        transition: transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s,-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s;
                        transform: scaleX(1) translateZ(0);
                    }
                }
                &.icon-bottom {
                    &::before {
                        transition: transform .6s cubic-bezier(0.165,0.84,0.44,1),-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1);
                        transform: scaleX(0) translateZ(0);
                    }
                    &::after {
                        transition: transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s,-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s;
                        transform: scaleX(1) translateZ(0);
                    }
                }
            }
        }
    }
}

.burger-icon {
    position: relative;
    width: 24px;
    height: 20px;
    cursor: pointer;
    transition: all .25s cubic-bezier(.645,.045,.355,1);
    > span {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        transform: translateZ(0);
        transition: transform .6s cubic-bezier(.165,.84,.44,1),-webkit-transform .6s cubic-bezier(.165,.84,.44,1);
        &::before , &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: #333;
        }
        &.burger-icon-top {
            top: 5px;
            &::before {
                transition: transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s,-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s;
                transform: scaleX(1) translateZ(0);
                transform-origin: right;
            }
            &::after {
                transition: transform .6s cubic-bezier(0.165,0.84,0.44,1),-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1);
                transform: scaleX(0) translateZ(0);
                transform-origin: left;
            }
        }
        &.burger-icon-bottom {
            bottom: 5px;
            &::before {
                transition: transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s,-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s;
                transform: scaleX(1) translateZ(0);
                transform-origin: right;
            }
            &::after {
                transition: transform .6s cubic-bezier(0.165,0.84,0.44,1),-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1);
                transform: scaleX(0) translateZ(0);
                transform-origin: left;
            }
        }
    }
    &.burger-icon-white {
        > span {
            &::before , &::after {
                background-color: $white;
            }
        }
    }
    &:hover {
        span {
            &::before , &::after {
                background-color: $theme-color-blue;
            }
            &.burger-icon-top {
                &::before {
                    transition: transform .6s cubic-bezier(0.165,0.84,0.44,1),-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1);
                    transform: scaleX(0) translateZ(0);
                }
                &::after {
                    transition: transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s,-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s;
                    transform: scaleX(1) translateZ(0);
                }
            }
            &.burger-icon-bottom {
                &::before {
                    transition: transform .6s cubic-bezier(0.165,0.84,0.44,1),-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1);
                    transform: scaleX(0) translateZ(0);
                }
                &::after {
                    transition: transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s,-webkit-transform .6s cubic-bezier(0.165,0.84,0.44,1) .2s;
                    transform: scaleX(1) translateZ(0);
                }
            }
        }
    }
}

.body-overlay-1 {
    background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all .25s cubic-bezier(0.645,0.045,0.355,1);
    visibility: hidden;
    width: 100%;
    cursor: crosshair;
    z-index: 9999;
}
.mobile-menu-active {
    .body-overlay-1 {
        opacity: 1;
        visibility: visible;
    }
}

.main-wrapper {
	transition: all .25s cubic-bezier(0.645,0.045,0.355,1);
}
.mobile-menu-active {
    overflow: hidden;
    .main-wrapper{
        transform: translateX(-320px);
        @media #{$xs-layout} {
            transform: translateX(-200px);
        }
    }
}

.mobile-header-wrapper-style {
	position: fixed;
	top: 0;
	width: 360px;
	min-height: 100vh;
	bottom: 0;
	z-index: 111;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all .25s cubic-bezier(0.645,0.045,0.355,1);
	transform: translate(200px,0);
    background-color: $white;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.09);
    z-index: 9999;
    @media #{$xs-layout} {
        width: 300px;
    }
    &.sidebar-visible {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    .mobile-header-wrapper-inner {
        padding: 0px 0px 30px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: $theme-color-blue;
        .mobile-header-top {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 20px 30px;
            background-color: $white;
            align-items: center;
            @media #{$xs-layout} {
                padding: 20px 20px;
            }
            .mobile-header-logo {
                a {
                    display: block;
                    img {
                        width: 166px;
                    }
                }
            }
        }
        .mobile-header-content-area {
            padding: 30px 30px 30px;
            @media #{$xs-layout} {
                padding: 30px 20px 30px;
            }
            .mobile-menu-wrap {
                nav {
                    height: 100%;
                    .mobile-menu {
                        li {
                            display: block;
                            position: relative;
                            padding: 18px 0 20px;
                            border-bottom: 1px solid rgba(255,255,255,.15);
                            &:last-child {
                                border-bottom: none;
                            }
                            &.menu-item-has-children {
                                .menu-expand {
                                    top: 10px;
                                    right: 0%;
                                    position: absolute;
                                    cursor: pointer;
                                    z-index: 9;
                                    width: 40px;
                                    height: 40px;
                                    text-align: center;
                                    border-radius: 100%;
                                    background-color: transparent;
                                    color: $white;
                                    line-height: 40px;
                                    font-size: 18px;
                                }
                                &.active {
                                    > .menu-expand {
                                        background: rgba(255,255,255,.2);
                                        i::before{
                                            content:"\f106";
                                        }
                                    }
                                }
                            }
                            a {
                                font-size: 16px;
                                line-height: 1;
                                text-transform: capitalize;
                                font-weight: 500;
                                position: relative;
                                display: inline-block;
                                color: $white;
                                i {
                                    margin-right: 5px;
                                }
                            }
                            ul {
                                padding: 18px 0 0;
                                border-top: 1px solid rgba(255, 255, 255, 0.15);
                                margin-top: 20px;
                                li {
                                    padding: 10px 0 12px;
                                    border-bottom: none;
                                    &.menu-item-has-children {
                                        .menu-expand {
                                            top: 0px;
                                        }
                                    }
                                    a {
                                        font-size: 15px;
                                        color: rgba(255,255,255,.7);
                                        display: block;
                                    }
                                    ul {
                                        margin-top: 32px;
                                    }
                                }
                            }
                            &:hover {
                                > a {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
            .mobile-header-info-wrap {
                padding: 25px 0 30px;
                margin-bottom: 30px;
                .single-mobile-header-info {
                    position: relative;
                    margin-bottom: 13px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        font-size: 16px;
                        display: block;
                        color: $white;
                        font-weight: 500;
                        span {
                            float: right;
                            font-size: 18px;
                            position: relative;
                            top: 8px;
                            line-height: 1;
                        }
                    }
                    .lang-curr-dropdown {
                        margin-top: 5px;
                        display: none;
                        background-color: #fff;
                        box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
                        padding: 22px 30px 26px;
                        width: 100%;
                        z-index: 11;
                        ul {
                            li {
                                padding-bottom: 10px; 
                                &:last-child {
                                    padding-bottom: 0px;
                                }
                                a {
                                    font-size: 15px;
                                    color: #696969;
                                    font-weight: 400;
                                    &:hover {
                                        color: $theme-color-blue;
                                    }
                                }
                            }
                        }
                    }
                    &:hover > a {
                        color: rgba(255, 255, 255, 0.7);
                    }
                }
            }
            .mobile-social-icon {
                a {
                    display: inline-block;
                    width: 33px;
                    height: 33px;
                    line-height: 33px;
                    text-align: center;
                    border-radius: 100%;
                    color: $white;
                    font-size: 15px;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                    &.facebook {
                        background-color: #3b5999;
                        border: 1px solid #3b5999;
                        &:hover {
                            color: $black;
                            background-color: $white;
                            border: 1px solid $white;
                        }
                    }
                    &.twitter {
                        background-color: #55acee;
                        border: 1px solid #55acee;
                        &:hover {
                            color: $black;
                            background-color: $white;
                            border: 1px solid $white;
                        }
                    }
                    &.tumblr {
                        background-color: #35465c;
                        border: 1px solid #35465c;
                        &:hover {
                            color: $black;
                            background-color: $white;
                            border: 1px solid $white;
                        }
                    }
                    &.instagram {
                        background-color: #e4405f;
                        border: 1px solid #e4405f;
                        &:hover {
                            color: $black;
                            background-color: $white;
                            border: 1px solid $white;
                        }
                    }
                }
            }
            .mobile-header-border {
                border-bottom: 1px solid rgba(255,255,255,.15);
            }
        }
    }
}
.mobile-search {
    padding: 30px 0 30px;
}
.categori-button-active-2 {
	padding: 21px 0;
    display: block !important;
    i {
        float: right; 
        position: relative;
        top: 5px;
    }
}

@media #{$md-layout} {
    .header-bottom {
        padding: 25px 0;
    }
}
@media #{$xs-layout} {
    .header-bottom {
        padding: 20px 0;
    }
}






